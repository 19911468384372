.reply-table {
    border-collapse: collapse;
  font-size: 14px;

  & {
    th, td {
      border: 1px solid black;
      padding: 4px;
    }
  }
}
